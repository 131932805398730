import deLocale from "element-ui/lib/locale/lang/de"
import enLocale from "element-ui/lib/locale/lang/en"
import esLocale from "element-ui/lib/locale/lang/es"
import huLocale from "element-ui/lib/locale/lang/hu"
import Vue from "vue"
import VueI18n from "vue-i18n"

import adminFields from "@/config/admin-fields.json"
import deepmerge from "deepmerge"
import de from "./de.json"
import en from "./en.json"
import es from "./es.json"
import hu from "./hu.json"
import deIntegrations from "./integrations/de.json"
import enIntegrations from "./integrations/en.json"
import esIntegrations from "./integrations/es.json"
import huIntegrations from "./integrations/hu.json"

Vue.use(VueI18n)

const messages = {
  en: deepmerge.all([en, enIntegrations, enLocale, adminFields], { clone: false }),
  de: deepmerge.all([de, deIntegrations, deLocale, adminFields], { clone: false }),
  hu: deepmerge.all([hu, huIntegrations, huLocale, adminFields], { clone: false }),
  es: deepmerge.all([es, esIntegrations, esLocale, adminFields], { clone: false }),
}

const fallbackLocale = {
  en: ["de"],
  hu: ["en", "de", "hu"],
  es: ["en", "de", "es"],
}

if (location.href.includes("translate=1")) {
  const ach = Object.values<any>(import.meta.glob("./ach.json", { eager: true }))[0].default
  Object.assign(messages, { ach: deepmerge.all([ach, enLocale, adminFields], { clone: false }) })
  Object.assign(fallbackLocale, { ach: ["en", "de"] })
}

const i18n = new VueI18n({
  locale: "de", // set locale
  messages: messages as any, // set locale messages
  fallbackLocale: fallbackLocale,
})

export default i18n
