<template>
  <dv-row
    :theme="field.theme"
    :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`"
    :hint="field.hint"
    class="flex-col"
    :class="{
      openai:
        $db.shopData.openaiConnected &&
        !record.received_via_mls &&
        record.id &&
        ['description_note', 'location_note', 'facebook_post_description', 'instagram_post_description'].includes(
          field.fieldName
        ),
    }"
    :field="field"
  >
    <template v-slot:label>
      <div class="flex justify-between items-center w-100">
        <p>{{ field.formField.label }} {{ field.mandatory ? "*" : "" }}</p>
        <property-writer-link
          v-if="
            $db.shopData.openaiConnected &&
            !record.received_via_mls &&
            record.id &&
            ['description_note', 'location_note', 'facebook_post_description', 'instagram_post_description'].includes(
              field.fieldName
            ) &&
            !field.readonly
          "
          :field="field.fieldName.replace('_note', '')"
          :field-name="field.formField.label"
          :resource-id="record.id"
          :resource-type="resourceType"
          @submit="record[field.fieldName] = $event"
        />
      </div>
    </template>
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'w-full',
      ]"
    >
      <nice-input
        type="textarea"
        v-model="record[field.fieldName]"
        :readonly="field.readonly"
        :autosize="{ minRows: 3, maxRows: 60 }"
        v-bind="field.formField.additionalParams && field.formField.additionalParams(field, record[field.fieldName])"
        :class="{ 'disabled-visibility-text': field.readonly }"
        :style="!record[field.fieldName] && `background-color: ${field.color}`"
        class="rounded"
        :data-value-missing="!record[field.fieldName]"
      />
      <footer v-if="snippets && snippets.length > 0 && !field.readonly" class="flex justify-end mt-1">
        <nice-select
          @change="snippetSelected"
          :options="snippets"
          size="mini"
          :placeholder="$t('detailView.snippetPh')"
          :class="{ 'disabled-visibility-text': field.readonly }"
        />
      </footer>
    </div>
  </dv-row>
</template>

<script>
import PropertyWriterLink from "@/components/property/PropertyWriterLink.vue"

export default {
  props: ["record", "field"],
  components: { PropertyWriterLink },
  methods: {
    snippetSelected(val) {
      const found = this.snippets.find(o => o.id == val)

      if (found) {
        console.log(found)
        this.record[this.field.fieldName] = found.body
      }
    },
  },
  computed: {
    snippets() {
      if (this.field.formField.snippets) {
        return this.$db.shopData.textSnippets.filter(
          o => o.category == this.field.formField.snippets && o.role != "long"
        )
      }
    },
    resourceType() {
      return this.$route.path.split("/")[2] === "properties" ? "property" : "project"
    },
  },
}
</script>

<style>
.disabled-visibility-text > textarea {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
.disabled-visibility-text > .el-input > span {
  display: none !important;
}
.openai > .box-cell > span {
  width: 100%;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
