<template>
  <dv-row :theme="field.theme" :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`" :hint="field.hint">
    <div class="w-full grid gap-2 grid-flow-col">
      <div
        v-if="field1"
        :class="[
          {
            'mandatory-outline':
              field1.mandatory &&
              !field1.readonly &&
              (Array.isArray(record[field1.fieldName])
                ? !record[field1.fieldName].length
                : !record[field1.fieldName]) &&
              field1.submitAttempt,
          },
        ]"
      >
        <component :is="field1ComponentName" :record="record" :field="field1" />
      </div>
      <div
        v-if="field2"
        :class="[
          {
            'mandatory-outline':
              field2.mandatory &&
              !field2.readonly &&
              (Array.isArray(record[field2.fieldName])
                ? !record[field2.fieldName].length
                : !record[field2.fieldName]) &&
              field2.submitAttempt,
          },
        ]"
      >
        <component :is="field2ComponentName" :record="record" :field="field2" />
      </div>
      <div
        v-if="field3"
        :class="[
          {
            'mandatory-outline':
              field3.mandatory &&
              !field3.readonly &&
              (Array.isArray(record[field3.fieldName])
                ? !record[field3.fieldName].length
                : !record[field3.fieldName]) &&
              field3.submitAttempt,
          },
        ]"
      >
        <component :is="field3ComponentName" :record="record" :field="field3" />
      </div>
    </div>
  </dv-row>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import DateField from "./DateField.vue"
import DatetimeField from "./DatetimeField.vue"
import Dropdown from "./Dropdown.vue"
import List from "./List.vue"
import NumberField from "./NumberField.vue"
import BooleanField from "./BooleanField.vue"
import StringField from "./StringField.vue"
import PhoneField from "./PhoneField.vue"
import { Field, FormField } from "@/interfaces"
import { camelCase } from "@/utils/with-case"

const TYPE_COMPONENT_MAPPING = {
  number: "NumberField",
  string: "StringField",
  boolean: "BooleanField",
  date: "DateField",
  datetime: "DatetimeField",
  dropdown: "Dropdown",
  multiselect: "List",
  tags: "List",
  tagsByCategory: "List",
  phone: "PhoneField",
}

const ALLOWED_COMPONENTS = ["client-dropdown", "property-dropdown"]

export default Vue.extend({
  props: {
    record: Object as PropType<any>,
    field: Object as PropType<Field>,
    formFields: Object as PropType<Record<string, FormField>>,
  },
  methods: {
    getFieldName(idx) {
      return this.field.detailViewField.containerFieldNames[idx]
    },
    getFormField(idx) {
      const fieldName = this.getFieldName(idx)
      if (!fieldName) return
      return this.formFields[camelCase(fieldName)]
    },
    getField(idx) {
      const fieldName = this.getFieldName(idx)
      const formField = this.getFormField(idx)
      if (!formField || !fieldName) return
      return {
        componentName: "",
        fieldName: fieldName,
        formField: formField,
        readonly: this.field.readonly,
        color: this.field.color,
        containerPlaceholder: formField.label,
        mandatory: this.field.mandatory,
        submitAttempt: this.field.submitAttempt,
      }
    },
    getFieldComponentName(idx) {
      const formField = this.getFormField(idx)
      if (!formField) return
      if (ALLOWED_COMPONENTS.includes(formField.component as string)) return formField.component
      return TYPE_COMPONENT_MAPPING[formField.type || "string"]
    },
  },
  computed: {
    fieldName1(): string {
      return this.getFieldName(0)
    },
    fieldName2(): string {
      return this.getFieldName(1)
    },
    fieldName3(): string | undefined {
      return this.getFieldName(2)
    },
    formfield1(): FormField | undefined {
      return this.getFormField(0)
    },
    formfield2(): FormField | undefined {
      return this.getFormField(1)
    },
    formfield3(): FormField | undefined {
      return this.getFormField(2)
    },
    field1(): Field | undefined {
      return this.getField(0)
    },
    field2(): Field | undefined {
      return this.getField(1)
    },
    field3(): Field | undefined {
      return this.getField(2)
    },
    field1ComponentName(): string | undefined {
      return this.getFieldComponentName(0)
    },
    field2ComponentName(): string | undefined {
      return this.getFieldComponentName(1)
    },
    field3ComponentName(): string | undefined {
      return this.getFieldComponentName(2)
    },
  },
  components: {
    DateField,
    DatetimeField,
    Dropdown,
    List,
    NumberField,
    BooleanField,
    StringField,
    PhoneField,
    ClientDropdown: () => import("./client/Dropdown.vue"),
    PropertyDropdown: () => import("./property/Dropdown.vue"),
  },
})
</script>
