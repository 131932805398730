<template>
  <div :ref="parentDropzone ? '' : 'dropzone'" @click.stop>
    <button ref="uploadButton" class="hidden" type="button"></button>
    <slot v-bind="{ open, uploading }">
      <ps-button
        component-tag="a"
        icon="cloud-upload"
        :loading="uploading"
        @click="open"
        active
        :title="title"
        class="mr-0"
      />
    </slot>
  </div>
</template>

<script>
import Dropzone from "@/config/dropzone"

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    url: {
      type: String,
      default: "dummy",
    },
    acceptedFiles: {
      type: String,
      required: false,
    },
    onSend: {
      type: Function,
      required: false,
    },
    onAdded: Function,
    parentDropzone: {
      type: Boolean,
      default: false,
      required: false,
    },
    clientOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploading: false,
    }
  },
  methods: {
    open() {
      this.$refs.uploadButton.click()
    },
  },
  mounted() {
    const self = this
    new Dropzone(this.parentDropzone ? this.$parent.$refs.dropzone : this.$refs.dropzone, {
      url: this.url,
      maxFilesize: 1000, // 1 GB
      paramName: "file",
      withCredentials: true,
      previewsContainer: false,
      clickable: this.$refs.uploadButton,
      createImageThumbnails: false,
      acceptedFiles: this.acceptedFiles,
      timeout: 1000000, // 10 Minuten,
      autoProcessQueue: !this.clientOnly,
      dragenter(e) {
        $("div").addClass("drag-hover")
      },
      drop(e) {
        $("div").removeClass("drag-hover")
      },
      init() {
        this.on("addedfile", file => {
          self.uploading = true
          self.onAdded?.(file)
        })
        this.on("sending", (file, xhr, formData) => {
          console.log(file, xhr, formData)
          formData.append("filename", file.name)
          self.onSend?.(formData)
        })
        this.on("success", function (file, res) {
          self.uploading = false
          self.$emit("success", res)
        })
        this.on("error", function (file, err, xhr) {
          console.log(err)
          self.uploading = false
          let msg = typeof err == "string" ? err : ""
          if (!msg) msg = err.errors ? err.errors.join(", ") : this.$t("documents.uploadBtn")
          App.alert(msg)
        })
      },
    })
  },
}
</script>

<style scoped>
.drag-hover {
  color: #0f55eb;
  background: #0f55eb22 !important;
}
</style>
