<template>
  <div>
    <dv-row v-for="(number, $idx) in record.client_addresses" v-show="!number._destroy" :key="$idx" v-redacted>
      <template v-slot:label>
        <div class="d-flex">
          <span class="mr-2">
            {{ field.formField.label }} {{ field.mandatory ? "*" : "" }}
            <tip v-if="$idx == 0 && field.hint" :hint="field.hint" icon="info-circle" class="light" />
          </span>
          <tooltip v-if="number.value" :content="$t('detailView.phoneCall')" placement="right">
            <a
              :href="$customFilters.phonify(number.value)"
              tabindex="-1"
              class="light mr-1"
              :data-client-id="record.id"
            >
              <fa-icon name="phone" />
            </a>
          </tooltip>
          <span v-if="$idx != 0 && !field.readonly" @click="setPrimary($idx)" class="px-1 pointer">
            <fa-icon name="arrow-up" class="action" :title="$t('detailView.phonePrimary')" />
          </span>
          <span v-if="!field.readonly" @click="addAdditionalRow" class="action px-1 pointer">
            <fa-icon name="plus" :title="$t('detailView.phoneAdd')" />
          </span>
          <span v-if="!field.readonly" @click="remove($idx)" class="action px-1 pointer">
            <fa-icon name="minus" :title="$t('detailView.phoneRemove')" />
          </span>
        </div>
      </template>
      <input
        type="tel"
        v-model="number.value"
        :placeholder="$t('detailView.phoneNumber')"
        :readonly="field.readonly"
        class="mr-2"
        :class="{
          'disabled-visibility-phonenumbers': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !number.value && field.submitAttempt,
        }"
      />
      <input
        type="text"
        v-model="number.label"
        :placeholder="$t('detailView.phoneText')"
        :readonly="field.readonly"
        :class="{ 'disabled-visibility-phonenumbers': field.readonly }"
      />
    </dv-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { Field } from "../../interfaces"

export default Vue.extend({
  props: {
    record: Object,
    field: Object as PropType<Field>,
  },
  watch: {
    "record.client_addresses": {
      handler(newValue) {
        if (!this.record.client_addresses.length) this.addAdditionalRow()
        return
      },
    } as any,
  },
  methods: {
    phoneNumberChanged() {
      if (this.record.client_addresses.filter(ca => !ca.value).length <= 0) {
        this.addAdditionalRow()
      }
    },
    setPrimary(index) {
      this.record.client_addresses = [this.record.client_addresses[index]]
        .concat(this.record.client_addresses.filter((o, i) => i != index))
        .map((o, i) => ({ ...o, position: i }))
    },
    remove($idx) {
      const newValue = JSON.parse(JSON.stringify(this.record.client_addresses))
      newValue[$idx]._destroy = true
      this.$set(this.record, "client_addresses", newValue)
    },
    addAdditionalRow() {
      this.record.client_addresses.push({
        label: "",
        value: "",
      })
    },
  },

  created() {
    if (!this.record.client_addresses.length) this.addAdditionalRow()
  },
})
</script>

<style lang="scss" scoped>
.primary-actions {
  .fal {
    opacity: 0;
    &.primary {
      opacity: 1 !important;
    }
  }
}
.box-row:hover {
  .primary-actions {
    .fal {
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.action.primary {
  opacity: 1 !important;
}

.action {
  opacity: 0;
}
.box-row:hover {
  .action {
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
}

.disabled-visibility-phonenumbers {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
