<template>
  <form-dialog
    :autofocus="false"
    :visible="visible"
    :title="$t('panes.messageForm.contactAcceptanceRequest.title')"
    append-to-body
    @close="$emit('update:visible', false)"
    :saving="submitting"
    @submit="submit"
    :submitButtonTitle="$t('panes.messageForm.contactAcceptanceRequest.submit')"
  >
    <p class="whitespace-pre-wrap" v-html="$t('panes.messageForm.contactAcceptanceRequest.text', { name: label })" />
  </form-dialog>
</template>
<script lang="ts">
import Vue, { PropType } from "vue"

export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Object as PropType<{ name: string; email: string; id: number }>,
    },
  },
  data() {
    return {
      submitting: false,
    }
  },
  computed: {
    label(): string | null {
      return !!this.client
        ? this.client.name + " (" + (this.$root.backdoor ? "Redacted" : this.client.email) + ")"
        : null
    },
  },
  methods: {
    submit() {
      this.$api
        .mutation("sendContactAcceptanceRequest", { clientId: this.client.id })
        .then(() => {
          this.$emit("update:visible", false)
          App.flashy(this.$t("panes.messageForm.contactAcceptanceRequest.success"))
        })
        .catch(this.$axios.handleError)
    },
  },
})
</script>
