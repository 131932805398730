<template>
  <component
    :is="url ? 'router-link' : 'span'"
    class="py-1 px-3 relative rounded standard-hover flex justify-between text-black -mx-4"
    :to="url"
  >
    <main>
      <p>
        {{ title }}
        <tooltip v-if="representingIcon" :content="`${$t('commissionSplits.representing')}: ${representingTooltip}`">
          <fa-icon class="ml-1 text-gray-600 text-sm" :name="representingIcon" />
        </tooltip>
        <!-- <span class="text-gray-600 text-sm">{{ item.splitPosition }}</span> -->
      </p>
      <footer class="text-gray-600 text-sm">{{ item.name }}</footer>
    </main>
    <span class="ml-1 font-mono text-sm">
      <span class="text-gray-600" v-if="item.valueType == 'fixed'">
        {{ $customFilters.numberToCurrency(item.value, currency) }}
      </span>
      <span
        v-else
        :title="`${item.value}% von ${$customFilters.numberToCurrency(record.total_commission, currency)}`"
        class="text-purple-800"
      >
        {{ calculatedValue }}
      </span>
    </span>
    <hover-actions>
      <hover-action size="sm" @click.stop.prevent="$emit('edit', item)" icon="pencil" :title="$t('detailView.edit')" />
      <hover-action
        size="sm"
        @click.stop.prevent="remove"
        icon="trash-alt"
        :title="$t('detailView.relationship.delete')"
        class="text-danger"
      />
    </hover-actions>
  </component>
</template>

<script>
import { calcCommissionValue } from "@/config/property/commission-split-calc"
import ConnectionItem from "@/components/detail-view/ConnectionItem"
import { unitMapping } from "@/config/units"

export default {
  props: ["item", "record"],
  components: {
    ConnectionItem,
  },
  methods: {
    remove() {
      this.$warn(
        {
          title: this.$t("detailView.relationship.delete"),
          desc: this.$t("detailView.relationship.delConfirm"),
          confirmButtonText: this.$t("detailView.relationship.delBtn"),
          redButton: true,
        },
        () => {
          this.$emit("remove", this.item)
        }
      )
    },
  },
  computed: {
    url() {
      if (this.item.client) return `/contacts/clients/${this.item.client.id}`
    },
    title() {
      return this.item.client?.name || this.item.broker?.name || this.$t("detailView.relationship.deleted")
    },
    calculatedValue() {
      const value = calcCommissionValue(this.record, this.item)
      return this.$customFilters.numberToCurrency(value, this.currency)
    },
    currency() {
      return unitMapping[this.record.currency || this.$db.shopData.isoCurrency]
    },
    representingIcon() {
      return {
        seller: "long-arrow-right",
        buyer: "long-arrow-left",
        both: "arrows-h",
      }[this.item.representing]
    },
    representingTooltip() {
      return this.$t("commissionSplits.representings")[[this.item.representing]]
    },
  },
}
</script>
