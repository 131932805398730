<template>
  <div class="relative w-full rounded">
    <!-- <input
      type="text"
      v-model="record[field.fieldName]"
      :readonly="field.readonly"
      class="dv-input"
      :style="{ 'padding-right': field.formField.limit ? '50px' : undefined }"
    /> -->
    <nice-input
      type="text"
      v-model="record[field.fieldName]"
      :readonly="field.readonly"
      v-bind="field.formField.additionalParams && field.formField.additionalParams(field, record[field.fieldName])"
      :class="{
        'el-input__char-counter': field.formField.limit,
        'disabled-visibility-string': field.readonly,
      }"
      :style="!record[field.fieldName] && `background-color: ${field.color}ff`"
      :data-value-missing="!record[field.fieldName]"
      class="rounded"
      :placeholder="field.containerPlaceholder"
    />
    <char-counter
      v-if="field.formField.limit"
      :count="record[field.fieldName] ? record[field.fieldName].length : 0"
      :total="field.formField.limit"
    />
  </div>
</template>

<script>
export default {
  props: ["record", "field"],
}
</script>

<style lang="scss">
.el-input__char-counter > .el-input__inner {
  padding-right: 50px !important;
}
.disabled-visibility-string > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
</style>