<template>
  <el-input
    ref="input"
    :type="type"
    :value="value"
    :maxlength="maxlength"
    :minlength="minlength"
    :showWordLimit="showWordLimit"
    :placeholder="placeholder"
    :clearable="clearable"
    :showPassword="showPassword"
    :disabled="disabled"
    :size="size"
    :prefixIcon="prefixIcon"
    :suffixIcon="suffixIcon"
    :rows="rows"
    :autosize="autosize"
    :autocomplete="autocomplete"
    :name="name"
    :readonly="readonly"
    :max="max"
    :min="min"
    :step="step"
    :resize="resize"
    :autofocus="autofocus"
    :form="form"
    :label="label"
    :tabindex="tabindex"
    :validateEvent="validateEvent"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @change="$emit('change', $event)"
    @input="handleInput"
    @clear="$emit('input', $event)"
  >
      <template v-slot:prefix>
        <slot name="prefix"/>
      </template>
      <template v-slot:suffix>
        <slot name="suffix"/>
      </template>
      <template v-slot:prepend>
        <slot name="prepend"/>
      </template>
      <template v-slot:append>
        <slot name="append"/>
      </template>
  </el-input>
</template>

<script>
export default {
  props: {
    value: {},
    type: {
      type: String
    },
    maxlength: {},
    minlength: {
      type: Number
    },
    showWordLimit: {
      type: Boolean
    },
    placeholder: {
      type: String
    },
    clearable: {
      type: Boolean
    },
    showPassword: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    prefixIcon: {
      type: String
    },
    suffixIcon: {
      type: String
    },
    rows: {
      type: Number
    },
    autosize: {},
    autocomplete: {
      type: String
    },
    name: {
      type: String
    },
    readonly: {
      type: Boolean
    },
    max: {},
    min: {},
    step: {
      type: String
    },
    size: {},
    resize: {
      type: String
    },
    autofocus: {
      type: Boolean
    },
    form: {
      type: String
    },
    label: {
      type: String
    },
    tabindex: {
      type: String
    },
    validateEvent: {
      type: Boolean
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    blur() {
      this.$refs.input.blur()
    },
    select() {
      this.$refs.input.select()
    },
    handleInput(value) {
      if (this.type == "number" && value == "") value = null
      if (this.type == "number" && value != null) value = Number(value)

      this.$emit("input", value)
    },
  }
}
</script>
