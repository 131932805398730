import { formatDefaultFields, formatFields } from "@/config/detail-view-form"
import i18n from "@/i18n/index"
import { DetailViewGroup } from "@/interfaces"

export const COMMERCIAL_TYPES = ["OFFICE", "GASTRONOMY", "STORE", "INDUSTRY", "SPECIAL_PURPOSE", "INVESTMENT"]
export const LIVING_TYPES = ["APARTMENT", "HOUSE", "SHORT_TERM_ACCOMODATION"]

export const DEFAULT_FIELDS: DetailViewGroup[] = [
  {
    slot: "left",
    name: "basic",
    detailViewFields: formatFields([
      "category",
      "property_status_id",
      "exposee_id",
      "unit_id",
      "title",
      "project_id",
      "group_ids",
      "warning_notice",
      "note",
    ]),
  },
  {
    slot: "left",
    name: "address",
    detailViewFields: formatFields([
      "address",
      {
        fieldName: "container",
        containerFieldNames: ["lat", "lng"],
        title: () => i18n.t("properties.formFields.coordinates") as string,
      },
      "location_id",
      "cadastral_district",
      "plot_number",
      "district",
      { fieldName: "aut_cadastral_community", filter: db => ["austria"].includes(db.internationalCountry) },
      "hide_address",
    ]),
  },
  {
    slot: "left",
    name: "pricing",
    detailViewFields: formatFields([
      { fieldName: "price", allowedMarketingType: "BUY", filter: db => !["austria"].includes(db.internationalCountry) },
      {
        fieldName: "price_net",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "BUY",
        title: () => i18n.t("properties.formFields.price_net") as string,
      },
      {
        fieldName: "price_vat",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "BUY",
        title: () => i18n.t("properties.formFields.price_vat") as string,
      },
      {
        fieldName: "price",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "BUY",
        title: () => i18n.t("properties.formFields.price_at") as string,
      },
      { fieldName: "custom_price_per_sqm", allowedRsTypes: ["INVESTMENT"] },
      {
        fieldName: "price_on_inquiry",
        allowedMarketingType: "BUY",
        hint: () => i18n.t("property.priceOnInquiryTooltip") as string,
        filter: db => !["austria"].includes(db.internationalCountry),
      },
      { fieldName: "for_bidding", allowedMarketingType: "BUY" },
      {
        fieldName: "recurring_costs_net",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "BUY",
        title: () => i18n.t("properties.formFields.recurring_costs_net") as string,
      },
      {
        fieldName: "recurring_costs_vat",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "BUY",
        title: () => i18n.t("properties.formFields.recurring_costs_vat") as string,
      },
      {
        fieldName: "recurring_costs",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "BUY",
        title: () => i18n.t("properties.formFields.recurring_costs") as string,
      },
      { fieldName: "base_rent", filter: db => !["austria"].includes(db.internationalCountry) },
      { fieldName: "total_rent", filter: db => !["austria"].includes(db.internationalCountry) },
      {
        fieldName: "base_rent_net",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.base_rent_net") as string,
      },
      {
        fieldName: "base_rent_vat",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.base_rent_vat") as string,
      },
      {
        fieldName: "base_rent",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.base_rent_at") as string,
      },
      {
        fieldName: "total_rent_net",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.total_rent_net") as string,
      },
      {
        fieldName: "total_rent_vat",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.total_rent_vat") as string,
      },
      {
        fieldName: "total_rent",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.total_rent_at") as string,
      },
      {
        fieldName: "other_costs_net",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.other_costs_net") as string,
      },
      {
        fieldName: "other_costs_vat",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
        title: () => i18n.t("properties.formFields.other_costs_vat") as string,
      },
      { fieldName: "heating_costs", filter: db => !["austria"].includes(db.internationalCountry) },
      {
        fieldName: "heating_costs_net",
        filter: db => ["austria"].includes(db.internationalCountry),
        title: () => i18n.t("properties.formFields.heating_costs_net") as string,
      },
      {
        fieldName: "heating_costs_vat",
        filter: db => ["austria"].includes(db.internationalCountry),
        title: () => i18n.t("properties.formFields.heating_costs_vat") as string,
      },
      {
        fieldName: "service_charge_net",
        filter: db => ["austria"].includes(db.internationalCountry),
        title: () => i18n.t("properties.formFields.service_charge_net") as string,
      },
      {
        fieldName: "service_charge_vat",
        filter: db => ["austria"].includes(db.internationalCountry),
        title: () => i18n.t("properties.formFields.service_charge_vat") as string,
      },
      {
        fieldName: "financial_contribution",
        filter: db => ["austria"].includes(db.internationalCountry),
        title: () => i18n.t("properties.formFields.financial_contribution") as string,
      },
      {
        fieldName: "construction_type",
        filter: db => ["austria"].includes(db.internationalCountry),
      },
      { fieldName: "service_charge", filter: db => !["austria"].includes(db.internationalCountry) },
      { fieldName: "heating_costs_in_service_charge", filter: db => !["austria"].includes(db.internationalCountry) },
      { fieldName: "other_costs", filter: db => !["austria"].includes(db.internationalCountry) },
      {
        fieldName: "aut_main_rent",
        filter: db => ["austria"].includes(db.internationalCountry),
        allowedMarketingType: "RENT",
      },
      { fieldName: "rent_subsidy", filter: db => !["austria"].includes(db.internationalCountry) },
      "maintenance_reserve",
      { fieldName: "deposit", allowedMarketingType: "RENT" },
      { fieldName: "parking_space_price", filter: db => !["austria"].includes(db.internationalCountry) },
      { fieldName: "rental_income", allowedMarketingType: "BUY" },
      {
        fieldName: "compensation_costs",
        filter: db => ["austria"].includes(db.internationalCountry),
      },
    ]),
  },
  {
    slot: "left",
    name: "areas",
    detailViewFields: formatFields([
      { fieldName: "net_floor_space", allowedRsTypes: [...COMMERCIAL_TYPES] },
      { fieldName: "total_floor_space", allowedRsTypes: [...COMMERCIAL_TYPES] },
      { fieldName: "living_space", allowedRsTypes: [...LIVING_TYPES, "INVESTMENT"] },
      { fieldName: "industrial_area", allowedRsTypes: ["INVESTMENT"] },
      "plot_area",
      { fieldName: "additional_area", allowedRsTypes: ["INVESTMENT"] },
      "number_of_rooms",
      { fieldName: "number_of_bed_rooms", allowedRsTypes: [...LIVING_TYPES] },
      { fieldName: "number_of_bath_rooms", allowedRsTypes: [...LIVING_TYPES] },
      { fieldName: "usable_floor_space", allowedRsTypes: [...LIVING_TYPES] },
      {
        fieldName: "min_divisible",
        allowedRsTypes: [...COMMERCIAL_TYPES, "TRADE_SITE"],
      },
      { fieldName: "balcony_space", allowedRsTypes: [...LIVING_TYPES] },
      { fieldName: "garden_space", allowedRsTypes: [...LIVING_TYPES] },
      "floor",
      "number_of_floors",
      "floor_position",
    ]),
  },
  {
    slot: "left",
    name: "investment",
    allowedRsTypes: ["INVESTMENT"],
    detailViewFields: formatFields([
      "number_of_apartments",
      "number_of_commercials",
      "number_of_units",
      {
        fieldName: "container",
        containerFieldNames: ["rental_income_actual", "rental_income_target"],
        title: () => i18n.t("properties.formFields.rental_income_container") as string,
      },
      {
        fieldName: "container",
        containerFieldNames: ["yield_actual", "yield_target"],
        title: () => i18n.t("properties.formFields.yield_container") as string,
      },
      {
        fieldName: "container",
        containerFieldNames: ["price_multiplier", "price_multiplier_target"],
        title: () => i18n.t("properties.formFields.price_multiplier_container") as string,
      },
      "investment_category",
      "purchase_form",
      "walt",
      "number_of_single_rooms",
      "single_rooms_quota",
      "occupancy_rate",
      "number_of_vacancies",
      "conservation_areas",
    ]),
  },
  {
    slot: "left",
    name: "additionalInfo",
    detailViewFields: formatFields([
      "rented",
      "free_from",
      "condition",
      "last_refurbishment",
      "interior_quality",
      "number_of_parking_spaces",
      "parking_space_types",
      { fieldName: "construction_phase", allowedRsTypes: ["HOUSE"] },
    ]),
  },
  {
    slot: "left",
    name: "plot",
    allowedRsTypes: ["TRADE_SITE"],
    detailViewFields: formatFields([
      "tenancy",
      "commercialization_type",
      "utilization_trade_site",
      "recommended_use_types",
      "site_development_type",
      "site_constructible_type",
      "grz",
      "gfz",
      "bgf",
      "bmz",
      "short_term_constructible",
      "building_permission",
      "preliminary_enquiry",
      "demolition",
    ]),
  },
  {
    slot: "left",
    name: "industry",
    allowedRsTypes: ["STORE", "INDUSTRY"],
    detailViewFields: formatFields([
      "location_classification_type",
      "shop_window_length",
      "goods_lift",
      "goods_lift_load",
      "floor_load",
      "supply_type",
      "lan_cables",
      "ramp",
      "auto_lift",
      "hall_height",
      "connected_load",
      "crane_runway_load",
    ]),
  },
  {
    slot: "left",
    name: "shortTerm",
    allowedRsTypes: ["SHORT_TERM_ACCOMODATION"],
    detailViewFields: formatFields([
      "price_interval_type",
      "start_rental_date",
      "end_rental_date",
      "min_rental_time",
      "max_rental_time",
      "max_number_of_persons",
      "has_furniture",
      "gender",
    ]),
  },
  {
    slot: "left",
    name: "facilities",
    splitIntoTwoColumns: true,
    detailViewFields: formatFields([
      "lift",
      "barrier_free",
      "cellar",
      "guest_toilet",
      "built_in_kitchen",
      "balcony",
      "loggia",
      "garden",
      "monument",
      "summer_residence_practical",
      "storeroom",
      "chimney",
      "swimming_pool",
      "sauna",
      "alarm_system",
      "winter_garden",
      "air_conditioning",
      { fieldName: "flat_share_suitable", allowedMarketingType: "RENT" },
      { fieldName: "non_smoker", allowedMarketingType: "RENT" },
      { fieldName: "pets_allowed", allowedMarketingType: "RENT" },
      "bathroom",
      "flooring_type",
    ]),
  },
  {
    slot: "right",
    name: "contact",
    detailViewFields: formatFields([
      "broker_id",
      "owners",
      "partners",
      {
        fieldName: "rs_kaufer",
        allowedMarketingType: "BUY",
        filter: db => db.relationshipPartnerNames.map(r => r.id).includes("kaufer"),
      },
      {
        fieldName: "rs_mieter",
        allowedMarketingType: "RENT",
        filter: db => db.relationshipPartnerNames.map(r => r.id).includes("mieter"),
      },
    ]),
  },
  {
    slot: "right",
    name: "portals",
    detailViewFields: formatFields([
      "portals",
      { fieldName: "scout_contact_id", filter: db => !["austria"].includes(db.internationalCountry) },
      { fieldName: "scout_id", filter: db => !["austria"].includes(db.internationalCountry) },
      { fieldName: "scout_group_number", filter: db => !["austria"].includes(db.internationalCountry) },
      "translations",
    ]),
  },
  {
    slot: "right",
    name: "contract",
    detailViewFields: formatFields([
      "contract_type",
      "duration",
      {
        fieldName: "container",
        containerFieldNames: ["duration_from", "duration_until"],
        title: () => i18n.t("properties.formFields.duration_container") as string,
      },
    ]),
  },
  {
    slot: "right",
    name: "sale",
    allowedMarketingType: "BUY",
    detailViewFields: formatFields([
      {
        fieldName: "container",
        containerFieldNames: ["valuation_price_from", "valuation_price_to"],
        title: () => i18n.t("properties.formFields.valuation_price_container") as string,
      },
      "sold_date",
      "sold_price",
      "co_ownership_share",
      "local_court",
      "register_number",
      "land_registry",
    ]),
  },
  {
    slot: "right",
    name: "provision",
    detailViewFields: formatFields([
      "commission",
      "total_commission",
      "internal_brokerage",
      "courtage",
      "courtage_note",
    ]),
  },
  {
    slot: "right",
    name: "energyCertificate",
    detailViewFields: formatFields([
      "energy_certificate_availability",
      "energy_certificate_creation_date",
      "energy_certificate_start_date",
      "energy_certificate_end_date",
      "building_energy_rating_type",
      "energy_efficiency_class",
      "thermal_characteristic",
      "thermal_characteristic_electricity",
      "thermal_characteristic_heating",
      "co_emission",
      "energy_consumption_contains_warm_water",
      "heating_type",
      "firing_types",
      "construction_year",
      "equipment_technology_construction_year",
      "construction_year_unknown",
      { fieldName: "aut_hwb", filter: db => ["austria"].includes(db.internationalCountry) },
      { fieldName: "aut_hwb_class", filter: db => ["austria"].includes(db.internationalCountry) },
      { fieldName: "aut_fgee", filter: db => ["austria"].includes(db.internationalCountry) },
      { fieldName: "aut_fgee_class", filter: db => ["austria"].includes(db.internationalCountry) },
    ]),
  },
  {
    slot: "right",
    name: "travelTimes",
    detailViewFields: formatFields([
      {
        fieldName: "container",
        containerFieldNames: ["distance_to_pt", "distance_to_pt_in_km"],
        title: () => i18n.t("properties.formFields.distance_to_pt") as string,
      },
      {
        fieldName: "container",
        containerFieldNames: ["distance_to_fm", "distance_to_fm_in_km"],
        title: () => i18n.t("properties.formFields.distance_to_fm") as string,
      },
      {
        fieldName: "container",
        containerFieldNames: ["distance_to_mrs", "distance_to_mrs_in_km"],
        title: () => i18n.t("properties.formFields.distance_to_mrs") as string,
      },
      {
        fieldName: "container",
        containerFieldNames: ["distance_to_airport", "distance_to_airport_in_km"],
        title: () => i18n.t("properties.formFields.distance_to_airport") as string,
      },
    ]),
  },
  {
    slot: "left",
    name: "description",
    detailViewFields: formatFields(["description_note", "location_note", "furnishing_note", "other_note"]),
  },
  {
    slot: "left",
    name: "longDescription",
    detailViewFields: formatFields([
      "long_description_note",
      "long_location_note",
      "long_furnishing_note",
      "long_other_note",
    ]),
  },
  {
    slot: "left",
    name: "lpSettings",
    detailViewFields: formatFields(["custom_lp_address", "landing_page_blocked"]),
  },
  {
    slot: "left",
    name: "portalContact",
    detailViewFields: formatFields(["openimmo_firstname", "openimmo_lastname", "openimmo_email", "openimmo_phone"]),
  },
]

export const getDefaultFields = db => {
  return formatDefaultFields(DEFAULT_FIELDS, db.shopData, "customFieldGroupsForProperties")
}
