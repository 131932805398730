<template>
  <dv-row :label="`${field.formField.label} ${field.mandatory ? '*' : ''}`" :hint="field.hint">
    <div
      v-if="record[field.fieldName]"
      :class="{ disabled: record[removeFieldKey] }"
      class="w-100 d-flex align-items-end"
    >
      <div>
        <img :src="record[field.fieldName]" width="50" />
      </div>
      <small v-if="!record[removeFieldKey] && !field.readonly">
        <a href="#" @click.prevent="markAsDelete" class="text-danger ml-2">{{ $t("detailView.photoRemove") }}</a>
      </small>
    </div>
    <div
      v-else-if="!field.readonly"
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'flex justify-between w-100',
      ]"
    >
      <input
        class="box-field"
        type="text"
        v-model="record[remoteFieldKey]"
        :placeholder="$t('detailView.photoAdd')"
        :readonly="field.readonly"
      />
      <span class="mx-2 mt-1 text-gray-400">{{ $t("detailView.or") }}</span>
      <upload-button
        :title="$t('detailView.photoUpload')"
        :url="`/api/v1/${uploadType}/${record.id}/upload`"
        accepted-files="image/*"
        @success="record[field.fieldName] = $event.url"
      />
    </div>
    <div v-else class="flex items-center w-100">
      <span>{{ $t("detailView.photoEmpty") }}</span>
    </div>
  </dv-row>
</template>

<script>
import UploadButton from "@/components/media/UploadButton"
export default {
  props: ["record", "field"],
  components: { UploadButton },
  methods: {
    markAsDelete() {
      this.record[this.removeFieldKey] = true
    },
  },
  computed: {
    fieldKey() {
      return this.field.fieldName.replace("_url", "")
    },
    removeFieldKey() {
      return `remove_${this.fieldKey}`
    },
    remoteFieldKey() {
      return `remote_${this.field.fieldName}`
    },
    uploadType() {
      return this.field.fieldName == "photo_url" ? "contacts" : "projects"
    },
  },
}
</script>

<style scoped>
.box-field {
  background: rgba(242, 241, 238, 0.6);
  border: none;
  height: 30px;
  width: 100%;
  line-height: initial;
  padding: 3px 6px;
  border-radius: 3px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px inset, rgba(15, 15, 15, 0.1) 0px 1px 1px inset;
  min-width: 0; /* firefox bugfix, see https://stackoverflow.com/a/48326796/5718861 */
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
