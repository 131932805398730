export { default as RS_TYPES } from "./rs-types"
export { default as RS_CATEGORIES } from "./rs-categories"
import CustomColumnMixin from "../../mixins/custom-columns"
import * as _ from "lodash"

export const DEFAULT_COLUMNS = []

export const SORT_OPTIONS = [
  { value: "exposee_id.number", fieldName: "exposee_id" },
  { value: "construction_year" },
  { value: "unit_id.raw", fieldName: "unit_id" },
  { value: "floor" },
  { value: "created_at" },
  { value: "property_space_value", fieldName: "space" },
  { value: "district.keyword", fieldName: "district" },
  { value: "plot_area" },
  { value: "base_rent" },
  { value: "price" },
  { value: "city.raw", fieldName: "city" },
  { value: "object_price", fieldName: "prop_price" },
  { value: "price_per_sqm", fieldName: "custom_price_per_sqm" },
  { value: "property_status_position", fieldName: "property_status_id" },
  { value: "status_updated_at" },
  { value: "street_number.raw", fieldName: "street" },
  { value: "sold_date" },
  { value: "total_rent" },
  { value: "number_of_rooms" },
  { value: "reserved_client_properties.deal_stage_chance", fieldName: "highest_stage" },
  { value: "updated_at" },
  { value: "duration_until" },
]

export const formatOptions = options => {
  const connections = _.keyBy(
    options.connections.map(c => ({ ...c, children: [] })),
    c => c.id
  )
  Object.values(connections).forEach(
    c => c.parentConnectionId && connections[c.parentConnectionId]?.children.push(c.id)
  )
  return {
    connections: connections,
    dealStages: _.keyBy(options.dealStages, c => c.id),
    brokers: _.keyBy(options.brokers || [], c => c.id),
    propertyStatuses: _.keyBy(options.propertyStatuses, c => c.id),
    propertyGroups: _.keyBy(options.propertyGroups, c => c.id),
    teams: _.keyBy(options.teams, c => c.id),
    projects: _.keyBy(options.projects, c => c.id),
  }
}

export const formatRow = (p, options) => ({
  ...p,
  path: `/portfolio/properties/${p.id}`,
  highest_deal_stage: p.highest_deal ? options.dealStages[p.highest_deal.deal_stage_id] : undefined,
  highest_aquisition_deal_stage: p.highest_aquisition_deal
    ? options.dealStages[p.highest_aquisition_deal.deal_stage_id]
    : undefined,
  highest_purchase_deal_stage: p.highest_purchase_deal
    ? options.dealStages[p.highest_purchase_deal.deal_stage_id]
    : undefined,
  highest_rental_deal_stage: p.highest_rental_deal
    ? options.dealStages[p.highest_rental_deal.deal_stage_id]
    : undefined,
  broker: options.brokers[p.broker_id],
  status: options.propertyStatuses[p.property_status_id],
  team: options.teams[p.team_id],
  project: options.projects[p.project_id],
  get_price: p.marketing_type === "RENT" ? p.base_rent : p.price,
  groups: p.property_group_ids.map(id => options.propertyGroups[id]?.name).filter(Boolean),
  publishings: p.publishings
    .map(id => {
      const connection = options.connections[id]
      if (connection && !connection.name?.endsWith("– Homepage") && connection.children?.length <= 0) return connection
    })
    .filter(Boolean),
})

const BROKER_ATTRIBUTE_FOR_STORING_COLUMNS_CONFIG = "customColumnsForProperties"

export const CustomColumnsMixin = CustomColumnMixin(BROKER_ATTRIBUTE_FOR_STORING_COLUMNS_CONFIG)

export const ALLOWED_HEADERS = [
  "strasse",
  "hausnummer",
  "plz",
  // "stadt",
  "ort",
  "land_iso",
  "notiz",
  "geolage",
  "etagenlage",
  "flurstueck",
  "gemarkung",
  "Art",
  "wohnungstyp",
  "objekttitel",
  "einheitennummer",
  "auftragsnummer",
  "geschoss",
  "geschosszahl",
  "zimmer",
  "wohnflaeche",
  "kaufpreis",
  "status",
  "badezimmeranzahl",
  "schlafzimmeranzahl",
  "qualitaet_ausstattung",
  "letzte_modernisierung",
  "zustand",
  "balkon",
  "fahrstuhl",
  "barrierefrei",
  "ebk",
  "keller",
  "garten",
  "gaestewc",
  "stellplatznummer",
  "stellplatzanzahl",
  "stellplatzpreis",
  "stellplatztyp",
  "baujahr",
  "provisionsbetrag",
  "provisionshinweis",
  "energietraeger",
  "frei_ab",
  "heizungsart",
  "nutzflaeche",
  "hausgeld",
  "mieteinnahmen",
  "nettomiete_ist",
  "nettomiete_soll",
  "vermietet",
  "denkmalschutz",
  "ferienwohnung_geeignet",
  "kaltmiete",
  "warmmiete",
  "nebenkosten",
  "heizkosten",
  "heizkosten_in_nebenkosten",
  "haustiere_erlaubt",
  "kaution",
  "wg_geeignet",
  "wohnberechtigungsschein_erforderlich",
  "energieausweis",
  "energieerstellungsdatum",
  "energieausweistyp",
  "energieeffizienzklasse",
  "energieverbrauchskennwert",
  "energieverbrauch_fuer_warmwasser_enthalten",
  "ausstattung",
  "sonstiges",
  "objektbeschreibung",
  "lage",
  "objekttitel_en",
  "objektbeschreibung_en",
  "lage_en",
  "ausstattung_en",
  "sonstiges_en",
  "grundstuecksflaeche",
  "typ",
  "nutzfläche",
  "balkonflaeche",
  "abstellraum",
  "bodenbelag",
  "balkonanzahl",
]

export const languagesMapping = {
  "zh-CN": "Chinesisch",
  de: "Deutsch",
  en: "Englisch",
  fr: "Französisch",
  it: "Italienisch",
  ru: "Russisch",
  es: "Spanisch",
  hu: "Ungarisch",
}
