import * as _ from "lodash"

export const getLocationGroups = db => {
  const locations = db.shopData.locations.map(({ id: value, name: label, parentLocationId }) => ({
    value,
    label,
    parentLocationId,
  }))
  const lookup = {}
  locations.forEach(cur => (lookup[cur.value] = cur))
  locations.forEach(location => {
    if (!location.parentLocationId) return
    const entry = lookup[location.parentLocationId]
    if (!entry) return
    return (entry.children || (entry.children = [])).push(location)
  })
  return locations.filter(location => !location.parentLocationId)
}

export const getLocationGroupsFlat = db => {
  let locations = db.shopData.locations
  const lookup = {}
  locations.forEach(cur => (lookup[cur.id] = cur))
  locations.forEach(location => {
    if (!location.parentLocationId) return
    const entry = lookup[location.parentLocationId]
    if (!entry) return
    location.parent = entry
  })

  return _.orderBy(
    locations.map(current => {
      let name = current.name
      const id = current.id
      while (current.parent) {
        current = current.parent
        name = `${current.name} → ${name}`
      }
      return { name, id }
    }),
    "name",
    "asc"
  )
}
