<template>
  <div>
    <dv-row :hint="field.hint">
      <template v-slot:label>
        <span v-html="$t('properties.formFields.internal_commission')"></span>
        <span v-if="field.mandatory">*</span>
      </template>
      <div
        :class="[
          {
            'mandatory-outline':
              field.mandatory &&
              !field.readonly &&
              !field.readonly &&
              !record.internal_commission_percentage &&
              field.submitAttempt,
          },
          'flex w-100',
        ]"
      >
        <nice-input
          size="small"
          type="number"
          v-if="internalCommissionType === 'percentage'"
          v-model="record.internal_commission_percentage"
          @change="record.internal_commission = null"
          :disabled="field.readonly"
        />
        <currency-input
          v-else
          v-model="record.internal_commission"
          @change.native="record.internal_commission_percentage = null"
          :disabled="field.readonly"
          :currency="currency"
          show-decimals
        />
        <nice-radio-group
          v-model="internalCommissionType"
          class="ml-2 whitespace-nowrap"
          :disabled="field.readonly"
          :options="[
            { id: 'percentage', name: '%' },
            { id: 'fixed', name: currency },
          ]"
        />
      </div>
    </dv-row>
    <dv-row>
      <template v-slot:label>
        <span v-html="$t('properties.formFields.external_commission')"></span>
        <span v-if="field.mandatory">*</span>
      </template>
      <div
        :class="[
          {
            'mandatory-outline':
              field.mandatory &&
              !field.readonly &&
              !field.readonly &&
              !record.external_commission_percentage &&
              field.submitAttempt,
          },
          'flex w-100',
        ]"
      >
        <nice-input
          size="small"
          type="number"
          v-if="externalCommissionType === 'percentage'"
          v-model="record.external_commission_percentage"
          @change="record.external_commission = null"
          :disabled="field.readonly"
        />
        <currency-input
          v-else
          v-model="record.external_commission"
          @change.native="record.external_commission_percentage = null"
          :disabled="field.readonly"
          :currency="currency"
          show-decimals
        />
        <nice-radio-group
          v-model="externalCommissionType"
          class="ml-2 whitespace-nowrap"
          :disabled="field.readonly"
          :options="[
            { id: 'percentage', name: '%' },
            { id: 'fixed', name: currency },
          ]"
        />
      </div>
    </dv-row>
  </div>
</template>

<script>
import { unitMapping } from "@/config/units"

export default {
  props: ["record", "field"],
  data() {
    return {
      internalCommissionType: this.record.internal_commission_percentage
        ? "percentage"
        : this.record.internal_commission
        ? "fixed"
        : "percentage",
      externalCommissionType: this.record.external_commission_percentage
        ? "percentage"
        : this.record.external_commission
        ? "fixed"
        : "percentage",
    }
  },
  computed: {
    currency() {
      return unitMapping[this.record.currency || this.$db.shopData.isoCurrency]
    },
  },
}
</script>

<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
