<template>
  <el-select
    ref="select"
    :disabled="disabled"
    :multiple="multiple"
    :clearable="clearable"
    :allow-create="allowCreate"
    default-first-option
    filterable
    v-cancel-read-only
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @clear="$emit('clear')"
    @blur="$emit('blur', $event)"
    @visible-change="$emit('visible-change', $event)"
    :placeholder="placeholder"
    :collapse-tags="collapseTags"
    :size="size"
    :data-highlight-on-empty="highlightOnEmpty && !value"
    v-bind="$attrs"
  >
    <template v-if="grouped">
      <el-option-group v-for="group in filteredOptions" :key="group.id" :label="group.name">
        <el-option
          v-for="item in group[childrenKey]"
          :label="item[labelKey]"
          :key="item.id"
          :value="item.id"
          :disabled="item.disabled"
        >
          <slot name="option" v-bind="{ item }">{{ item[labelKey] }}</slot>
        </el-option>
      </el-option-group>
    </template>
    <template v-else>
      <el-option
        v-for="item in filteredOptions"
        :label="item[labelKey]"
        :key="item[idKey]"
        :value="item[idKey]"
        :disabled="(itemDisabledFn && itemDisabledFn(item)) || item.disabled"
      >
        <slot name="option" v-bind="{ item }">{{ item[labelKey] }}</slot>
      </el-option>
    </template>
  </el-select>
</template>

<script>
export default {
  props: {
    value: {},
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: false },
    collapseTags: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$t("niceSelect.placeholder")
      },
    },
    itemDisabledFn: { type: Function },
    size: { type: String, default: "small" },
    labelKey: { type: String, default: "name" },
    idKey: { type: String, default: "id" },
    childrenKey: { type: String, default: "children" },
    options: {},
    grouped: { type: Boolean, default: false },
    highlightOnEmpty: { type: Boolean, default: false },
  },

  computed: {
    filteredOptions() {
      if (!Array.isArray(this.options)) {
        return _.map(this.options, (label, key) => ({ id: key, [this.labelKey]: label }))
      }

      return this.options
    },
  },

  expose: ["focus"],

  emits: ["input", "change", "blur", "visible-change"],

  methods: {
    focus() {
      this.$refs.select.focus()
    },
  },
}
</script>
