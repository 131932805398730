<template>
  <a :content="hintHTML" v-tippy>
    <fa-icon :name="icon" />
  </a>
</template>

<script>
export default {
  props: {
    hint: {
      type: String,
    },
    icon: {
      type: String,
      default: "question-circle",
    },
  },
  computed: {
    hintHTML() {
      return this.$customFilters.simpleFormat(this.hint)
    },
  },
}
</script>