<template>
  <dv-row>
    <template v-slot:label>
      {{ field.formField.label }} {{ field.mandatory ? "*" : "" }}
      <a
        v-if="record[field.fieldName]"
        :href="$customFilters.urlify(record[field.fieldName])"
        tabindex="-1"
        target="_blank"
        class="light ml-1"
      >
        <fa-icon name="external-link" />
      </a>
    </template>
    <input
      type="text"
      v-model="record[field.fieldName]"
      :readonly="field.readonly"
      :class="{
        'disabled-visibility-link': field.readonly,
        'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt,
      }"
      :style="!record[field.fieldName] && `background-color: ${field.color}`"
    />
  </dv-row>
</template>

<script>
export default {
  props: ["record", "field"],
}
</script>

<style>
.disabled-visibility-link {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
