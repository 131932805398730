<template>
  <date-picker
    :readonly="field.readonly"
    v-model="record[field.fieldName]"
    type="datetime"
    format="dd.MM.yyyy HH:mm"
    class="w-100 rounded"
    prefix-icon="ignore"
    size="mini"
    :class="{
      'disabled-visibility-date': field.readonly,
    }"
    :style="!record[field.fieldName] && `background-color: ${field.color}`"
    :data-value-missing="!record[field.fieldName]"
  />
</template>

<script>
export default {
  props: ["record", "field"],
}
</script>

<style>
.disabled-visibility-date > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}
</style>