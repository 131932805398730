<template>
  <dv-row>
    <template v-slot:label>
      <span v-html="field.formField.label"></span>
      <span v-if="field.mandatory">*</span>
    </template>
    <div
      :class="[
        { 'mandatory-outline': field.mandatory && !field.readonly && !record[field.fieldName] && field.submitAttempt },
        'flex w-100',
      ]"
    >
      <el-select
        :disabled="field.readonly"
        filterable
        clearable
        v-cancel-read-only
        default-first-option
        v-model="record[field.fieldName]"
        :placeholder="$t('detailView.placeholder')"
        size="mini"
        v-bind="field.formField.additionalParams && field.formField.additionalParams(field, record[field.fieldName])"
        :class="{ 'disabled-visibility-dropdown': field.readonly }"
        :style="!record[field.fieldName] && field.color && `background-color: ${field.color}`"
        class="rounded"
        :data-value-missing="!record[field.fieldName]"
      >
        <el-option v-for="o in options" :key="o.id" :value="o.id" :label="o.name" />
      </el-select>
      <hover-action v-if="record.energieausweis48_order_url && !field.readonly" class="ml-2" @click="openPage">
        <span class="text-green-600">Bestellen</span>
      </hover-action>
    </div>
  </dv-row>
</template>

<script>
export default {
  props: ["record", "field"],
  methods: {
    openPage() {
      window.open(this.record.energieausweis48_order_url, "_blank")
    },
  },
  computed: {
    options() {
      const mapping = this.$db.shopData.propertyOptionsMapping.energy_certificate_availability
      return Object.keys(mapping).map(key => ({ id: key, name: mapping[key] }))
    },
  },
}
</script>

<style scoped>
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
