<template>
  <!-- <el-select
    :disabled="field.readonly"
    filterable
    clearable
    v-cancel-read-only
    default-first-option
    v-model="record[field.fieldName]"
    :placeholder="$t('detailView.placeholder')"
    size="mini"
    v-bind="field.formField.additionalParams && field.formField.additionalParams(field, record[field.fieldName])"
    :class="{
      'disabled-visibility-dropdown': field.readonly,
    }"
    :remote="async"
    :remote-method="async ? remoteMethod : undefined"
    @change="preselect"
    :style="!record[field.fieldName] && field.color && `background-color: ${field.color}`"
    class="rounded"
    :data-value-missing="!record[field.fieldName]"
  >
    <el-option v-for="o in totalOptions" :key="o.id" :value="o.id" :label="o.name" :disabled="o.disabled" />
  </el-select> -->
  <db-select :disabled="field.readonly" :collection="field.formField.dbOptions" v-model="record[field.fieldName]" />
</template>

<script>
export default {
  props: ["record", "field"],
}
</script>

<style>
.disabled-visibility-dropdown > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  border: rgb(223, 223, 223) 1px solid !important;
  color: black !important;
  box-shadow: none !important;
  opacity: 0.67 !important;
}

.disabled-visibility-dropdown > .el-input > span {
  display: none !important;
}

.disabled-visibility-dropdown {
  border-radius: 2px !important;
}
</style>
