<template>
  <div class="relative w-full rounded">
    <nice-slider
      v-model="record[field.fieldName]"
      :readonly="field.readonly"
      :max="field.formField.max"
      :step="field.formField.step"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { Field } from "@/interfaces"

export default Vue.extend({
  props: {
    record: Object as PropType<any>,
    field: Object as PropType<Field>,
  },
})
</script>

<style scoped>
</style>
