<template>
  <form-dialog
    :visible="visible"
    @update:visible="updateVisible"
    :submit-func="submit"
    @complete="complete"
    :title="title"
    :append-to-body="true"
  >
    <template v-if="editable">
      <header class="flex justify-end" v-if="$db.shopData.deeplConnected">
        <nice-button
          type="text"
          size="small"
          @click="autoTranslate"
          class="flex items-center"
          :loading="autoTranslating"
          :disabled="autoTranslationCompleted"
        >
          <span class="text-green-600">{{ $t("detailView.translations.autoTranslate") }}</span>
          <img src="@/assets/images/deepl.png" style="height: 24px" class="ml-1" />
        </nice-button>
      </header>
      <form-section>
        <form-row :title="$t('detailView.translations.title')" direction="column">
          <nice-input size="small" v-model="editable.title" />
        </form-row>
        <form-row :title="$t('detailView.translations.availability')" direction="column">
          <nice-input size="small" v-model="editable.freeFrom" />
        </form-row>
      </form-section>
      <form-section>
        <form-row :title="$t('detailView.translations.deposit')" direction="column">
          <nice-input size="small" v-model="editable.deposit" />
        </form-row>
        <form-row :title="$t('detailView.translations.courtage')" direction="column">
          <nice-input size="small" v-model="editable.courtage" />
        </form-row>
        <form-row :title="$t('detailView.translations.courtageNote')" direction="column">
          <nice-input type="textarea" v-model="editable.courtageNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
      </form-section>
      <form-section>
        <form-row :title="$t('properties.formFields.description_note')" direction="column">
          <nice-input type="textarea" v-model="editable.descriptionNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
        <form-row :title="$t('properties.formFields.location_note')" direction="column">
          <nice-input type="textarea" v-model="editable.locationNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
        <form-row :title="$t('properties.formFields.furnishing_note')" direction="column">
          <nice-input type="textarea" v-model="editable.furnishingNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
        <form-row :title="$t('properties.formFields.other_note')" direction="column">
          <nice-input type="textarea" v-model="editable.otherNote" :autosize="{ minRows: 3, maxRows: 30 }" />
        </form-row>
      </form-section>
      <form-section>
        <form-row :title="$t('properties.formFields.long_description_note')" direction="column">
          <rich-textarea v-model="editable.longDescriptionNote" />
        </form-row>
        <form-row :title="$t('properties.formFields.long_location_note')" direction="column">
          <rich-textarea v-model="editable.longLocationNote" />
        </form-row>
        <form-row :title="$t('properties.formFields.long_furnishing_note')" direction="column">
          <rich-textarea v-model="editable.longFurnishingNote" />
        </form-row>
        <form-row :title="$t('properties.formFields.long_other_note')" direction="column">
          <rich-textarea v-model="editable.longOtherNote" />
        </form-row>
      </form-section>
    </template>
  </form-dialog>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { PropertyTranslation } from "@/interfaces"
import { languagesMapping } from "@/config/property"
import eventBus from "@/config/event-bus"

const DEFAULT_TRANSLATION: PropertyTranslation = {
  locale: "de",
  courtage: "",
  courtageNote: "",
  deposit: "",
  freeFrom: "",
  title: "",
  descriptionNote: "",
  locationNote: "",
  furnishingNote: "",
  otherNote: "",
  longDescriptionNote: "",
  longLocationNote: "",
  longFurnishingNote: "",
  longOtherNote: "",
}

export default Vue.extend({
  props: {
    locale: {
      type: String,
    },
    propertyId: {
      type: Number,
    },
    translations: {
      type: Array as PropType<PropertyTranslation[]>,
    },
  },
  data() {
    return {
      editable: null as unknown as any,
      autoTranslating: false,
      autoTranslationCompleted: false,
    }
  },
  watch: {
    locale(newValue: string): any {
      if (!this.locale) return (this.editable = null)

      const translation = this.translations.find(t => t.locale == newValue)
      if (translation) {
        this.editable = JSON.parse(JSON.stringify(translation))
        return
      }

      this.$graphql(
        `{
        shop {
          defaultCourtageEn defaultCourtageNoteEn defaultOtherNoteEn
          defaultCourtageEs defaultCourtageNoteEs defaultOtherNoteEs
        }
      }`
      ).then(({ shop }) => {
        if (this.locale == "en") {
          this.editable = {
            ...DEFAULT_TRANSLATION,
            courtage: shop.defaultCourtageEn,
            courtageNote: shop.defaultCourtageNoteEn,
            otherNote: shop.defaultOtherNoteEn,
            locale: newValue,
          }
        } else if (this.locale == "es") {
          this.editable = {
            ...DEFAULT_TRANSLATION,
            courtage: shop.defaultCourtageEs,
            courtageNote: shop.defaultCourtageNoteEs,
            otherNote: shop.defaultOtherNoteEs,
            locale: newValue,
          }
        } else {
          this.editable = {
            ...DEFAULT_TRANSLATION,
            locale: newValue,
          }
        }
      })
    },
  },
  methods: {
    async submit() {
      await this.$api.mutation("updateProperty", {
        id: this.propertyId,
        locale: this.locale,
        attributes: _.omit(this.editable, "locale"),
      })
    },
    complete() {
      this.$emit("complete", this.editable)
      App.flashy(this.$t("detailView.translations.updateSuccess"))
      eventBus.$emit("property-update")
    },
    updateVisible(visible: boolean) {
      if (!visible) {
        this.autoTranslationCompleted = false
        this.$emit("close")
      }
    },
    async autoTranslate() {
      try {
        this.autoTranslating = true
        const texts = await this.$api.mutation(
          "translatePropertyTexts",
          {
            resourceId: this.propertyId,
            resourceType: "property",
            targetLang: this.locale,
          },
          "title descriptionNote locationNote furnishingNote otherNote courtage courtageNote deposit freeFrom"
        )
        this.editable = { ...this.editable, ...texts }
        this.autoTranslationCompleted = true
      } catch (e) {
        this.$axios.handleError(e)
      } finally {
        this.autoTranslating = false
      }
    },
  },
  computed: {
    title(): any {
      return this.$t("detailView.translations.formTitle", { lang: languagesMapping[this.locale] })
    },
    visible(): boolean {
      return !!this.locale
    },
  },
})
</script>
