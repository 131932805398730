<template>
  <div>
    <dv-row v-for="(client, $idx) in relationships" v-show="!client._destroy" :key="$idx" :field="field">
      <template v-slot:label>
        <div class="d-flex">
          <span class="mr-2">
            {{ field.formField.label }} {{ field.mandatory ? "*" : "" }}
            <tip v-if="$idx == 0 && field.hint" :hint="field.hint" icon="info-circle" class="light" />
          </span>
          <span @click="addAdditionalRow" v-if="!field.readonly" class="action px-1 pointer">
            <fa-icon name="plus" :title="$t('detailView.relationship.add')" />
          </span>
          <span
            v-if="
              !field.readonly &&
              (relationships.filter(r => !r._destroy).length > 1 || relationships.filter(r => !r._destroy)[0].id)
            "
            @click="remove($idx, client)"
            class="action px-1 pointer"
          >
            <fa-icon name="minus" :title="$t('detailView.relationship.delete')" />
          </span>
        </div>
      </template>
      <router-link
        class="w-100 mt-1 link"
        v-if="client.id"
        :to="$customFilters.clientPath(client.relatedClientId, $db.broker)"
      >
        {{ client.relatedClient ? client.relatedClient.name : $t("detailView.ownerDeleted") }}
      </router-link>
      <client-select
        v-else
        v-model="client.relatedClientId"
        :disabled="field.readonly"
        :class="{
          'disabled-visibility-owner': field.readonly,
          'mandatory-outline': mandatoryMissing,
        }"
        :style="!client.relatedClientId && `background-color: ${field.color}ff`"
        class="rounded"
        :data-value-missing="!client.relatedClientId"
      />
      <input
        v-if="!field.fieldName.startsWith('rs_')"
        class="ml-2"
        type="text"
        v-model="client.name"
        :placeholder="$t('detailView.relationship.typeOpt')"
        :readonly="field.readonly"
        :class="{ 'disabled-visibility-owner-input': field.readonly }"
      />
    </dv-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { Field } from "../../interfaces"

export default Vue.extend({
  props: {
    record: Object,
    field: Object as PropType<Field>,
  },
  watch: {
    record() {
      if (!this.relationships.length) this.addAdditionalRow()
    },
  },
  methods: {
    remove($idx, client) {
      this.$set(client, "_destroy", true)
      if (this.relationships.filter(r => !r._destroy).length == 0) this.addAdditionalRow()
    },
    addAdditionalRow() {
      this.record.relationships.push({
        internalName: this.field.formField.scope || "partner",
        name: this.field.fieldName.startsWith("rs_") ? this.field.formField.label : null,
        relatedClientId: null,
      })
    },
  },
  computed: {
    relationships(): any {
      let fieldNames
      if (this.$db.broker.propertyDetailViewGroups.length) {
        const fields = this.$db.broker.propertyDetailViewGroups.map(group => group.detailViewFields).flat(1)
        fieldNames = fields.filter(field => field.fieldName.startsWith("rs_")).map(f => f.fieldName.substring(3))
      } else {
        fieldNames = ["kaufer", "mieter"]
      }
      const relationshipTypes = this.$db.shopData.relationshipPartnerNames
        .filter(n => fieldNames.includes(n.id))
        .map(n => n.name)
      return this.record.relationships.filter(
        r =>
          (r.internalName == this.field.formField.scope && !relationshipTypes.includes(r.name)) ||
          (r.internalName == "partner" && r.name == this.field.formField.label)
      )
    },
    mandatoryMissing(): boolean {
      if (this.field.readonly || !this.field.submitAttempt || !this.field.mandatory) return false
      switch (this.field.fieldName) {
        case "partners":
          return !this.record.relationships.filter(
            r => r.internalName === "partner" && !!r.relatedClientId && !r._destroy
          ).length
        case "owners":
          return !this.record.relationships.filter(
            r => r.internalName === "owner" && !!r.relatedClientId && !r._destroy
          ).length
        default:
          if (this.field.fieldName.startsWith("rs_")) {
            return !this.record.relationships.filter(
              r =>
                r.internalName === "partner" &&
                !!r.name &&
                this.field.formField.label === r.name &&
                !!r.relatedClientId &&
                !r._destroy
            ).length
          }
          return Array.isArray(this.record[this.field.fieldName])
            ? !this.record[this.field.fieldName].length
            : [undefined, null, ""].includes(this.record[this.field.fieldName])
      }
    },
  },

  created() {
    if (!this.relationships.length) this.addAdditionalRow()
  },
})
</script>

<style lang="scss" scoped>
.primary-actions {
  .fal {
    opacity: 0;
    &.primary {
      opacity: 1 !important;
    }
  }
}
.box-row:hover {
  .primary-actions {
    .fal {
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.action.primary {
  opacity: 1 !important;
}

.action {
  opacity: 0;
}
.box-row:hover {
  .action {
    opacity: 0.3;
    &:hover {
      opacity: 1;
    }
  }
}

.disabled-visibility-owner-input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  opacity: 0.67 !important;
}
.disabled-visibility-owner > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 1 !important;
}

.disabled-visibility-owner > .el-input > span {
  display: none !important;
}

.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
