<template>
  <div>
    <dv-row
      class="relative"
      :hint="field.hint"
      :class="{ 'pt-10': showLocalHero }"
      v-if="$db.broker.canExportToPortals"
    >
      <template v-slot:label>
        <strong>
          {{ field.formField.label || $t("detailView.portalExport.title") }} {{ field.mandatory ? "*" : "" }} ({{
            portals.length
          }})
        </strong>
        <span
          v-if="showLocalHero"
          class="text-sm absolute text-green-600 text-left right-0 left-0 -top-8 whitespace-nowrap"
        >
          {{ $t("localHero.hint") }}
        </span>
      </template>

      <hover-actions
        v-if="showLocalHero"
        :always-visible="true"
        right="0px"
        class="absolute right-0 -top-10"
        :disabled="field.readonly || exporting || Boolean(record.local_hero_booking)"
      >
        <hover-action @click="showLocalHeroForPortal(portals.find(p => p.format === 'IS24'))">
          <small class="text-green-600">{{ $t("localHero.publish") }}</small>
        </hover-action>
      </hover-actions>
      <hover-actions
        :always-visible="true"
        right="0px"
        :disabled="field.readonly || exporting || Boolean(record.local_hero_booking)"
      >
        <hover-action @click="exportProperty('ONLINE')">
          <small class="text-green-600">{{ $t("detailView.portalExport.publishAll") }}</small>
        </hover-action>
      </hover-actions>
    </dv-row>
    <item
      v-for="portal in portals"
      :key="portal.id"
      :record="record"
      :portal="portal"
      :exporting="exporting"
      :local-hero-available="showLocalHero"
      :local-hero-currently-booked="localHeroCurrentlyBooked"
      @export-success="localHeroAvailable = false"
      @show-local-hero="showLocalHeroForPortal(portal)"
      :disabled="field.readonly"
      @show-publishings-modal="publishingsModal = $event"
    >
      <div
        v-if="showOTA(portal)"
        class="bg-gray-100 rounded-lg mt-3 mb-2 flex justify-between items-center relative"
        style="padding: 0.75rem"
      >
        <promo-highlighter class="absolute text-xs" style="left: 8px; top: -18px">
          {{ $t("detailView.portalExport.ota.title") }}
        </promo-highlighter>
        <span>{{ $t("detailView.portalExport.ota.description") }}</span>
        <button
          @click.prevent="getOTA(portal)"
          class="py-2 px-4 bold rounded-lg text-black"
          style="background-color: #34ffd3"
        >
          <fa-icon name="chart-line" class="mr-2" />
          {{ $t("detailView.portalExport.ota.book") }}
        </button>
      </div>
    </item>
    <el-dialog
      :title="$t('detailView.portalExport.publishings')"
      width="500px"
      :visible="!!publishingsModal"
      @close="publishingsModal = null"
    >
      <alert v-if="!publishingDeletable" class="-mt-4 mb-2">
        <fa-icon name="info-circle" class="mr-2" />
        {{ $t("detailView.portalExport.instagramDeleteDesc") }}
      </alert>
      <div v-for="item in publishings" :key="item.id" class="standardList-item justify-between">
        <span>{{ $customFilters.datetime(item.publishDate) }}</span>
        <div>
          <a :href="item.postUrl" target="_blank" class="standardList-remove text-blue-500">
            <fa-icon name="external-link" />
          </a>
          <a
            v-if="publishingDeletable"
            href="#"
            @click.prevent="deletePublishing(item.id)"
            class="standardList-remove ml-3"
          >
            <fa-icon name="trash-alt" />
          </a>
        </div>
      </div>
    </el-dialog>
    <local-hero-dialog :visible.sync="localHeroModalVisible" :record="record" :portal="localHeroPortal" />
  </div>
</template>

<script lang="ts">
import eventBus from "@/config/event-bus"
import Vue from "vue"
import Item from "./PortalExportItem.vue"
import LocalHeroDialog from "@/components/property/LocalHeroDialog.vue"

export default Vue.extend({
  props: ["record", "field"],
  components: { Item, LocalHeroDialog },
  data() {
    return {
      exporting: false,
      localHeroAvailable: false,
      localHeroCurrentlyBooked: false,
      localHeroModalVisible: false,
      localHeroPortal: null as any,
      publishingsModal: null as any,
    }
  },
  watch: {
    "record.updated_at": function () {
      this.requestLocalHero()
    },
  },
  computed: {
    portals(): any {
      return this.$db.shopData.connections.filter(b => !b.parentConnectionId)
    },
    deletablePortals(): any {
      return this.portals.filter(portal => this.record.publishings.find(p => p.portalId == portal.id))
    },
    showLocalHero(): boolean {
      return this.localHeroAvailable && !this.localHeroCurrentlyBooked
    },
    publishings(): any {
      return this.record.publishings.filter(p => p.portalId == this.publishingsModal)
    },
    publishingDeletable(): boolean {
      const portal = this.portals.filter(p => p.id == this.publishingsModal)
      return portal.length ? portal[0].format == "FACEBOOK" : false
    },
  },
  methods: {
    subscribe() {
      this.$pusher.off(`cart_error@${this.record.id}`)
      this.$pusher.off(`cart_success@${this.record.id}`)

      this.$pusher.on(`cart_error@${this.record.id}`, data => {
        App.alert(data.error_messages.join(", "))
        eventBus.$emit("property-exported")
      })
      this.$pusher.on(`cart_success@${this.record.id}`, _ => {
        App.flashy(this.$t("detailView.portalExport.exportSuccess"))
        eventBus.$emit("property-exported")
      })
    },
    dateDiff(itemDate) {
      const today = new Date() as any
      const item = new Date(itemDate) as any
      return Math.round((today - item) / (1000 * 60 * 60 * 24))
    },
    deletePublishing(id) {
      const portalId = this.deletablePortals.filter(p => p.id === this.publishingsModal).map(p => p.id)
      this.$api
        .mutation("exportProperties", {
          propertyIds: [this.record.id],
          portalIds: portalId,
          publishingId: id,
          modus: "DELETE",
        })
        .then(_ => {
          App.flashy(this.$t("detailView.portalExport.publishingDeleted"))
        })
        .catch(this.$axios.handleError)
        .finally(() => {
          this.exporting = false
        })
    },
    exportProperty(modus: "ONLINE" | "DELETE") {
      if (this.exporting) return

      if (this.$db.broker.localHeroAutoPublish) {
        this.exportAsLocalHero()
      } else if (this.showLocalHero) {
        this.localHeroPortal = null
        this.localHeroModalVisible = true
        return
      }

      const portals = modus == "DELETE" ? this.deletablePortals : this.portals

      this.exporting = true
      this.$api
        .mutation("exportProperties", {
          propertyIds: [this.record.id],
          portalIds: portals.map(p => p.id),
          modus,
          localHeroCurrentlyBooked: this.localHeroCurrentlyBooked,
        })
        .then(_ => {
          App.flashy(this.$t("detailView.portalExport.exportAllSuccess"))
        })
        .catch(this.$axios.handleError)
        .finally(() => {
          this.exporting = false
        })
    },
    exportAsLocalHero() {
      this.$api
        .mutation(
          "exportPropertyAsLocalHero",
          {
            propertyId: this.record.id,
            portalId: this.localHeroPortal ? this.localHeroPortal.id : null,
            publishEverywhereAfterwards: !this.localHeroPortal,
          },
          "localHeroBooking { createdAt startsAt endsAt }"
        )
        .then(({ localHeroBooking }) => {
          this.record.skipDirty = true
          this.record.updated_at = (window as any).moment().format()
          this.record.local_hero_booking = localHeroBooking
          App.flashy(this.$t("detailView.portalExport.localHeroExportStarted"))
        })
        .catch(this.$axios.handleError)
    },
    async requestLocalHero() {
      try {
        const { localHeroAvailable } = await this.$graphql(
          `{ localHeroAvailable(propertyId: $propertyId) { generally currentlyBooked} }`,
          {
            propertyId: this.record.id,
          }
        )
        this.localHeroAvailable = localHeroAvailable.generally
        this.localHeroCurrentlyBooked = localHeroAvailable.currentlyBooked
      } catch (e) {
        this.$axios.handleError(e)
        this.localHeroAvailable = false
        this.localHeroCurrentlyBooked = false
      }
    },
    showLocalHeroForPortal(portal) {
      this.localHeroPortal = portal
      if (this.$db.broker.localHeroAutoPublish) {
        this.exportAsLocalHero()
      } else {
        this.localHeroModalVisible = true
      }
    },
    showOTA(portal) {
      if (
        portal.format === "IS24" &&
        ((!portal.is24OtoaAccessBrokerIds?.length && !portal.is24OtoaAccessDepartmentIds?.length) ||
          portal.is24OtoaAccessBrokerIds?.includes(this.$db.broker.id) ||
          portal.is24OtoaAccessDepartmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))
      ) {
        const published = this.record.publishings.some(p => p.portalId == portal.id)
        return !Boolean(this.record.local_hero_booking) && this.record.object_type == "LIVING" && published
      }
      return false
    },
    getOTA(portal) {
      const publishing = this.record.publishings.find(p => p.portalId == portal.id)
      const scoutTicketUrl = publishing.scoutTicketUrl

      eventBus.$emit("quick-view", {
        type: "OTA",
        mode: "edit",
        params: {
          url: scoutTicketUrl,
        },
      })
    },
  },
  beforeDestroy() {
    this.$pusher.off(`cart_error@${this.record.id}`)
    this.$pusher.off(`cart_success@${this.record.id}`)
  },
  mounted() {
    this.subscribe()
    if (this.$db.shopData.enabledScoutFeatures.includes("local_hero")) this.requestLocalHero()
  },
})
</script>
