<template>
  <el-button
    :type="type"
    :size="size"
    @click="$emit('click', $event)"
    :loading="loading"
    :class="computedStyleClass"
    :disabled="disabled"
    :plain="plain"
    :title="title"
    :round="round"
  >
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  props: {
    type: { type: String },
    size: { type: String },
    title: { type: String },
    loading: { type: Boolean },
    disabled: { type: Boolean, default: false },
    plain: { type: Boolean, default: false },
    round: { type: Boolean, default: false },
    additionalStyling: { type: String },
  },

  computed: {
    computedStyleClass() {
      if (this.additionalStyling) {
        return this.additionalStyling
      }
      return ""
    },
  },
}
</script>

<style>
.el-button--small {
  padding-top: 8px;
  padding-bottom: 8px;
}

.larger-font {
  font-size: 14px;
  font-weight: 400;
}

.item .el-button,
.formItem .el-button {
  height: 100%;
  border-radius: 0px 3px 3px 0px;
  padding: 0 10px;
}
</style>