<template>
  <div>
    <dv-row :label="$t(queryApp ? 'polygon' : 'client.queries.form.searchArea')" :hint="hint">
      <nice-input
        autocomplete="no"
        ref="search"
        v-model="search"
        :placeholder="$t(queryApp ? 'polygon' : 'client.queries.form.searchAreaPlaceholder')"
        size="small"
        class="mb-2"
        :disabled="disabled"
      />
    </dv-row>
    <div class="relative">
      <div
        v-if="showMap && !disabled"
        ref="map"
        style="min-width: 100%; min-height: 0px"
        class="mb-2"
        :style="{ 'padding-bottom': mapHeight }"
      ></div>
      <div v-if="showReset && !disabled" class="absolute" style="top: 10px; right: 10px">
        <button
          href="#"
          @click.prevent="reset"
          class="text-red-600 text-danger bg-white text-2xl font-bold border border-gray-200"
          style="width: 40px; height: 40px; line-height: 40px"
        >
          <i class="fal fa-times"></i>
        </button>
      </div>
      <div v-if="radius && !disabled" class="absolute" style="top: 10px; right: 50px">
        <input
          v-model="radius"
          class="pl-2 bg-white font-bold border border-gray-200"
          style="width: 80px; height: 40px; line-height: 40px"
        />
        <span style="margin-left: -18px">m</span>
      </div>
    </div>
  </div>
</template>

<script>
const gmapsGeometryPicker = ({ ref, zoom, color, center, initalShape, onShapeSelected, defaultType = "polygon" }) => {
  const SUPPORTED_TYPES = ["polygon", "circle"]

  let map
  let drawingManager
  let selectedShape
  let allShapes = []
  let selectedType = !!initalShape ? initalShape.type : defaultType

  const clearSelection = () => {
    if (!selectedShape) return
    selectedShape.setEditable(false)
    selectedShape = null
  }

  const setSelection = shape => {
    clearSelection()
    shape.setEditable(true)
    setColor(shape.get("fillColor") || shape.get("strokeColor"))
    selectedShape = shape
  }

  const removeShape = shape => {
    drawingManager.setOptions({
      drawingControl: true,
    })
    if (!shape) return
    shape.setMap(null)
    allShapes = allShapes.filter(s => s.id !== shape.id)
    shapeChanged([])
  }

  // const removeSelectedShape = () => {
  //   removeShape(selectedShape)
  // }

  const removeAllShapes = () => {
    allShapes.forEach(shape => removeShape(shape))
  }

  const setColor = color => {
    const polylineOptions = drawingManager.get("polylineOptions")
    polylineOptions.strokeColor = color
    drawingManager.set("polylineOptions", polylineOptions)
    const rectangleOptions = drawingManager.get("rectangleOptions")
    rectangleOptions.fillColor = color
    drawingManager.set("rectangleOptions", rectangleOptions)
    const circleOptions = drawingManager.get("circleOptions")
    circleOptions.fillColor = color
    drawingManager.set("circleOptions", circleOptions)
    const polygonOptions = drawingManager.get("polygonOptions")
    polygonOptions.fillColor = color
    drawingManager.set("polygonOptions", polygonOptions)
  }

  const setCenter = center => {
    map.setCenter(center)
  }

  const setRadius = radius => {
    const parsed = parseInt(radius)
    if ((selectedShape && selectedShape.type !== "circle") || parsed <= 0) return
    selectedShape.setRadius(parsed)
  }

  const shapeChanged = shape => {
    const type = shape.type
    switch (type) {
      case "polygon":
        onShapeSelected({
          values:
            shape && shape.getPath
              ? shape
                  .getPath()
                  .getArray()
                  .map(elem => [elem.lng(), elem.lat()])
              : [],
          type,
        })
        break
      case "circle":
        onShapeSelected({ values: [[shape.center.lng(), shape.center.lat()], Math.floor(shape.radius)], type })
        break
    }
  }

  const bindListeners = shape => {
    const type = shape.type
    google.maps.event.addListener(shape, "click", function () {
      setSelection(shape)
    })
    switch (type) {
      case "polygon":
        google.maps.event.addListener(shape, "rightclick", function (event) {
          if (!event.vertex) return
          if (shape.getPath().length <= 3) return
          shape.getPaths().getAt(event.path).removeAt(event.vertex)
        })
        shape.getPaths().forEach(function (path) {
          ;["insert_at", "remove_at", "set_at"].forEach(e => {
            google.maps.event.addListener(path, e, function () {
              shapeChanged(shape)
            })
          })
        })
        break
      case "circle":
        // google.maps.event.addListener(shape, "rightclick", event => {
        //   removeShape(shape)
        // })
        ;["center_changed", "radius_changed"].forEach(e => {
          google.maps.event.addListener(shape, e, () => {
            shapeChanged(shape)
          })
        })
        break
    }
  }

  map = new google.maps.Map(ref, {
    zoom: zoom,
    center: new google.maps.LatLng(center[1], center[0]),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    zoomControl: true,
  })

  const options = {
    strokeWeight: 0,
    fillOpacity: 0.45,
    editable: true,
  }

  drawingManager = new google.maps.drawing.DrawingManager({
    drawingMode: selectedType,
    drawingControlOptions: {
      drawingModes: SUPPORTED_TYPES,
    },
    markerOptions: {
      draggable: true,
    },
    polylineOptions: {
      editable: true,
    },
    rectangleOptions: options,
    circleOptions: options,
    polygonOptions: options,
    map: map,
  })

  setColor(color)

  if (initalShape && initalShape.values && initalShape.values.length) {
    drawingManager.setDrawingMode(null)
    drawingManager.setOptions({
      drawingControl: false,
    })
    let shape
    switch (initalShape.type) {
      case "polygon":
        shape = new google.maps.Polygon({
          path: initalShape.values.map(elem => new google.maps.LatLng(elem[1], elem[0])),
          editable: true,
          ...options,
        })
        break
      case "circle":
        shape = new google.maps.Circle({
          center: new google.maps.LatLng(initalShape.values[0][1], initalShape.values[0][0]),
          radius: initalShape.values[1],
          editable: true,
          ...options,
        })
        break
    }
    shape.type = initalShape.type

    shape.setMap(map)
    shape.id = Date.now()
    setSelection(shape)
    allShapes.push(shape)
    bindListeners(shape)

    let bounds = new google.maps.LatLngBounds()
    switch (initalShape.type) {
      case "polygon":
        shape
          .getPath()
          .getArray()
          .forEach(lngLat => bounds.extend(lngLat))
        break
      case "circle":
        bounds.union(shape.getBounds())
        break
    }
    setTimeout(() => {
      // map.setCenter(bounds.getCenter())
      // map.panToBounds(bounds)
      map.fitBounds(bounds)
    })
  }

  google.maps.event.addListener(drawingManager, "overlaycomplete", function (e) {
    if (e.type != google.maps.drawing.OverlayType.MARKER) {
      drawingManager.setDrawingMode(null)
      drawingManager.setOptions({
        drawingControl: false,
      })
      selectedType = e.type

      let shape = e.overlay
      shape.type = e.type
      shape.id = Date.now()
      bindListeners(shape)
      setSelection(shape)
      allShapes.push(shape)

      shapeChanged(shape)
    }
  })

  // google.maps.event.addDomListener(document, "keyup", function (e) {
  //   var code = e.keyCode ? e.keyCode : e.which
  //   if (code !== 27) return
  //   removeSelectedShape()
  //   drawingManager.setDrawingMode(null)
  //   drawingManager.setOptions({
  //     drawingControl: true,
  //   })
  //   shapeChanged()
  // })

  google.maps.event.addListener(drawingManager, "drawingmode_changed", clearSelection)
  google.maps.event.addListener(map, "click", clearSelection)

  return { clearSelection, removeAllShapes, setCenter, setRadius }
}
import FormRow from "@/components/form/Row"

export default {
  props: {
    zoom: {
      type: Number,
      default: 12,
    },
    value: {
      type: Object,
    },
    color: {
      type: String,
    },
    internationalCountry: {
      type: String,
    },
    international: {
      type: Boolean,
      default: false,
    },
    mapHeight: {
      type: String,
      default: "66%",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    queryApp: {
      type: Boolean,
      default: false,
    },
  },
  data(props) {
    return {
      drawer: null,
      autocomplete: null,
      search: "",
      showReset: props.value,
      radius: props.value && props.value[0] && props.value[1] === "circle" ? props.value[0][1] : undefined,
      showMap: !!props.value,
      center: [0, 0],
      forceRerenderMap: false,
    }
  },
  components: {
    FormRow,
  },
  watch: {
    disabled() {
      this.forceRerenderMap = true
    },
    radius(newVal) {
      if (newVal === undefined) return
      this.drawer.setRadius(newVal)
    },
  },
  methods: {
    reset() {
      this.showReset = false
      this.radius = undefined
      this.$emit("input", undefined)
      this.drawer.removeAllShapes()
    },
    setCenter(center) {
      this.drawer.setCenter(center)
    },
    initAutocomplete() {
      if (this.autocomplete) return
      const input = this.$refs.search.$refs.input.$refs.input
      const autocomplete = new google.maps.places.Autocomplete(input, {
        types: ["(regions)"],
        componentRestrictions: !!this.googleCountryRestriction ? { country: this.googleCountryRestriction } : undefined,
      })
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace()
        if (!place || !place.place_id) return // user wrote random shit
        this.search = ""
        this.showMap = true

        if (!this.drawer) {
          this.center = [place.geometry.location.lng(), place.geometry.location.lat()]
        } else {
          this.reset()
          this.setCenter(place.geometry.location)
        }
      })
      this.autocomplete = autocomplete
    },
    initGmaps() {
      if (!this.showMap || (this.drawer && !this.forceRerenderMap) || !this.$refs.map) return
      if (this.value && this.value.type === "circle") {
        this.radius = this.value.values[1]
      }
      this.drawer = gmapsGeometryPicker({
        ref: this.$refs.map,
        zoom: this.zoom,
        color: this.color,
        center: this.center,
        initalShape: this.value,
        onShapeSelected: ({ values, type }) => {
          this.showReset = values.length !== 0
          this.radius = type === "circle" ? values[1] : undefined
          this.$emit("input", { values, type })
        },
      })
      this.forceRerenderMap = false
    },
    // registerRefs() {
    //   if (!this.showMap) {
    //     if (this.autocomplete) return
    //     this.autocomplete = autocomplete
    //     this.drawer = null
    //   } else {
    //     if (this.drawer) return

    //     // this.autocomplete = null
    //   }
    // },
  },
  computed: {
    googleCountryRestriction() {
      const countryMapping = {
        swiss: "ch",
        austria: "at",
        spain: "es",
        hungary: "hu",
      }
      return this.international ? null : countryMapping[this.internationalCountry] || "de"
    },
  },
  updated() {
    this.initGmaps()
  },
  mounted() {
    this.initAutocomplete()
    this.initGmaps()
  },
}
</script>
