<template>
  <el-select
    v-if="$db.shopData.useStaticDropdownForCitySelect"
    size="small"
    filterable
    v-cancel-read-only
    multiple
    allow-create
    default-first-option
    v-model="record.cities"
    :placeholder="$t('client.queries.form.cityPh')"
  >
    <el-option v-for="item in $db.shopData.cities" :key="item" :label="item" :value="item" />
  </el-select>
  <city-select
    v-else
    v-model="record.cities"
    :sells-internationally="$db.shopData.sellsInternationally"
    :international-country="$db.shopData.internationalCountry"
  />
</template>

<script>
import Vue from "vue"
import CitySelect from "@/components/saved-query/CitySelect"

export default Vue.extend({
  props: ["record", "field"],
  components: {
    CitySelect,
  },
})
</script>