<template>
  <footer>
    <div class="relative my-1">
      <nice-progress :percentage="commissionSplitSumPercentage" :show-text="false" subtype="commissionBar" />
      <nice-progress
        v-if="remaining < 0"
        :percentage="excessCommissionSplitSumPercentage"
        :show-text="false"
        subtype="commissionBar"
        :excessBar="true"
        class="absolute top-0 left-0 right-0"
      />
    </div>
    <p class="text-sm font-medium">
      {{ $t("commissionSplits.total") }}: {{ $customFilters.numberToCurrency(commission, currency) }} |
      {{ $t("commissionSplits.alreadySplit") }}: {{ $customFilters.numberToCurrency(commissionSplitSum, currency) }} |
      {{ $t("commissionSplits.remaining") }}:
      {{ $customFilters.numberToCurrency(remaining, currency) }}
    </p>
  </footer>
</template>

<script lang="ts">
import { calcCommissionValue } from "@/config/property/commission-split-calc"
import Vue, { PropType } from "vue"
import { unitMapping } from "@/config/units"

export default Vue.extend({
  props: {
    record: {
      type: Object as PropType<any>,
      required: true,
    },
    actor: {
      type: String,
      required: true,
    },
  },
  computed: {
    currency(): string {
      return unitMapping[this.record.currency || this.$db.shopData.isoCurrency]
    },
    commission(): number {
      return this.actor == "buyer" ? this.record.external_commission : this.record.internal_commission
    },
    commissionSplits(): any[] {
      return this.record.commission_splits.filter(item => item.actor == this.actor)
    },
    commissionSplitSum(): number {
      return this.commissionSplits.reduce((acc, item) => acc + calcCommissionValue(this.record, item), 0)
    },
    commissionSplitSumPercentage(): number {
      if (!this.commissionSplitSum || !this.commission) return 0
      return (this.commissionSplitSum / this.commission) * 100
    },
    remaining(): number {
      return this.commission - this.commissionSplitSum
    },
    excessCommissionSplitSumPercentage(): number {
      if (!this.remaining || !this.commission) return 0
      return (Math.abs(this.remaining) / this.commission) * 100
    },
  },
})
</script>

<style>
</style>
