<template>
  <div>
    <dv-row
      :label="`${$t('detailView.nameSalutation')} ${field.mandatory ? '*' : ''}`"
      :hint="field.hint"
      :field="field"
    >
      <el-select
        filterable
        v-cancel-read-only
        clearable
        default-first-option
        v-model="record.salutation"
        placeholder
        size="mini"
        class="mr-2"
        :disabled="field.readonly"
        :class="{
          'disabled-visibility-name': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.salutation && field.submitAttempt,
        }"
      >
        <el-option v-for="o in salutations" :key="o.id" :value="o.id" :label="o.name" />
      </el-select>
      <div class="w-100">
        <input
          type="text"
          :placeholder="$t('detailView.nameTitle')"
          v-model="record.academic_title"
          class="dv-input"
          :readonly="field.readonly"
          :class="{ 'disabled-visibility-name': field.readonly }"
        />
      </div>
    </dv-row>
    <dv-row
      :label="`Name ${field.mandatory ? '*' : ''}`"
      :field="field"
      :copy-value="copyText"
      :copy-tooltip="$t('detailView.nameCopy')"
    >
      <input
        type="text"
        :placeholder="$t('detailView.nameFirstname')"
        v-model="record.first_name"
        class="dv-input mr-2"
        :readonly="field.readonly"
        :class="{
          'disabled-visibility-name': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.first_name && field.submitAttempt,
        }"
      />
      <input
        type="text"
        :placeholder="$t('detailView.nameLastname')"
        v-model="record.last_name"
        class="dv-input"
        :readonly="field.readonly"
        :class="{
          'disabled-visibility-name': field.readonly,
          'mandatory-outline': field.mandatory && !field.readonly && !record.last_name && field.submitAttempt,
        }"
      />
    </dv-row>
  </div>
</template>

<script>
const SALUTATION_OPTIONS = [
  { id: "mr", name: "nameSalutationMr" },
  { id: "ms", name: "nameSalutationMs" },
  { id: "mrms", name: "nameSalutationMrms" },
]

export default {
  props: ["record", "field"],
  computed: {
    salutations() {
      return SALUTATION_OPTIONS.map(o => ({ ...o, name: this.$t(`detailView.${o.name}`) })).concat(
        this.$db.get("salutations").map(o => ({ id: o.internalName, name: o.name }))
      )
    },
    salutationLabel() {
      return this.salutations.find(o => o.id == this.record.salutation)?.name
    },
    copyText() {
      return [this.salutationLabel, this.record.academic_title, this.record.first_name, this.record.last_name]
        .filter(Boolean)
        .join(" ")
    },
  },
}
</script>

<style>
.disabled-visibility-name {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}

.disabled-visibility-name > .el-input > input {
  background-color: rgb(245, 245, 245) !important;
  color: black !important;
  box-shadow: none !important;
  border: rgb(223, 223, 223) 1px solid !important;
  opacity: 0.67 !important;
}

.disabled-visibility-name > .el-input > span {
  display: none !important;
}
.mandatory-outline {
  border: none !important;
  border-radius: 2pt !important;
  box-shadow: 0 0 0 1pt rgb(255, 0, 0) !important;
}
</style>
