<template>
  <nice-select
    :value="value"
    :options="options"
    :multiple="multiple"
    :placeholder="$t('brokerDropdownSearch.search')"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  />
</template>

<script>
let cache

export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    }
  },
  methods: {
    fetchData() {
      this.$graphql(
        `query CommissionSplitBrokers {
        shop { commissionSplitBrokers { id name } }
      }`
      )
        .then(({ shop }) => {
          this.options = shop.commissionSplitBrokers
          cache = this.options
        })
        .catch(this.$axios.handleError)
    },
  },
  mounted() {
    if (cache) {
      this.options = cache
    } else {
      this.fetchData()
    }
  },
}
</script>
